<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="12"
    height="9"
    viewBox="0 0 12 9"
  >
    <defs>
      <path
        id="z05ea"
        d="M344.67 175.34a1.12 1.12 0 0 0-1.61 0l-5.7 5.88-2.43-2.5a1.11 1.11 0 0 0-1.6 0 1.2 1.2 0 0 0 0 1.65l3.2 3.3c.34.34.83.42 1.24.24.17-.06.33-.14.46-.28l6.44-6.63a1.2 1.2 0 0 0 0-1.66z"
      />
    </defs>
    <g>
      <g transform="translate(-333 -175)"><use fill="#363636" xlink:href="#z05ea" /></g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheCheckedMarkIcon',
};
</script>
